@font-face {
  font-family: "New York XL Heavy";
  font-style: heavy;
  font-weight: 800;
  src: url("./fonts/NewYorkExtraLarge-Heavy.otf");
}

@font-face {
  font-family: "New York XL Medium";
  font-style: medium;
  font-weight: 400;
  src: url("./fonts/NewYorkExtraLarge-Medium.otf");
}

.App {
  text-align: center;
  font-family: "New York";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  min-width: 320px;
}

img {
  max-width: 400px;
  min-width: 125px;
  margin-bottom: 48px;
}

h1,
h2 {
  margin: 0;
}

h1 {
  font-family: "New York XL Heavy";
}

h2,
p {
  font-family: "New York XL Medium";
}

@media only screen and (max-width: 320px) {
  img {
    max-width: 256px;
    margin-bottom: 48px;
  }
  h1 {
    font-size: 30px;
  }
}
